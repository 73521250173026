@import "src/theme/colors.module";
@import "src/theme/breakpoints.module";

.tabsBox {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 20px;
  background: #00635C;
}

.tabsMenuContainer {
  padding: 0 50px 10px;
  display: flex;
  justify-content: space-between;

  @include bp-below(md) {
    padding: 0 10px 10px;
  }

  .tabLink {
    color: #fff;
    font-weight: 300;
    padding: 10px;
    cursor: pointer;
    text-align: center;
  }

  .activeTabLink {
    @extend .tabLink;

    &:after {
      content: "";
      float: left;
      background: $color-green-200;
      width: 100%;
      margin-top: 5px;
      height: 3px;
      border-radius: 3px;
    }
  }
}

.paymentsListContainer {
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

