@import "src/theme/colors.module";
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 70px 0;
}

.circleCheck {
  height: 131px;
  margin-bottom: 31px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  color: $color-green-100;
  font-size: 24px;
  margin-bottom: 15px;
}

.text {
  display: flex;
  justify-content: center;
  color: $color-gray-100;
  font-size: 16px;
  line-height: 32px;
}
