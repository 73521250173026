$color-green-100: #00635c;
$color-green-200: #0bc268;

$color-orange-100: #FB7A32;

$color-white: #FFF;

$color-gray-100: #626D80;

$color-red-100: #C82333;
