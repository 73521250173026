@import "src/theme/colors.module";

.container {
  border-radius: 10px;
  background: $color-white;
}

.bodyContainer {
  padding: 20px;
}
