$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

@mixin bp-above($size) {
  @if $size == 'sm' {
    @media screen and (min-width: $breakpoint-sm) {
      @content;
    }
  }

  @if $size == 'md' {
    @media screen and (min-width: $breakpoint-md) {
      @content;
    }
  }

  @if $size == 'lg' {
    @media screen and (min-width: $breakpoint-lg) {
      @content;
    }
  }

  @if $size == 'xl' {
    @media screen and (min-width: $breakpoint-xl) {
      @content;
    }
  }

  @if $size == 'xxl' {
    @media screen and (min-width: $breakpoint-xxl) {
      @content;
    }
  }
}

@mixin bp-below($size) {
  @if $size == 'sm' {
    @media screen and (max-width: $breakpoint-sm) {
      @content;
    }
  }

  @if $size == 'md' {
    @media screen and (max-width: $breakpoint-md) {
      @content;
    }
  }

  @if $size == 'lg' {
    @media screen and (max-width: $breakpoint-lg) {
      @content;
    }
  }

  @if $size == 'xl' {
    @media screen and (max-width: $breakpoint-xl) {
      @content;
    }
  }

  @if $size == 'xxl' {
    @media screen and (max-width: $breakpoint-xxl) {
      @content;
    }
  }
}

