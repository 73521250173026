.colorBlack {
  color: #333;
}

.marginBottom2px {
  margin-bottom: 2px;
}

.marginBottom20px {
  margin-bottom: 20px;
}
