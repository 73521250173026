@import 'src/theme/colors.module';

.bankAdnotation,
.bankAdnotation:hover,
.bankAdnotationLink,
.bankAdnotationLink:hover {
  font-size: 12px;
  line-height: 16px;
  color: $color-green-100;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  text-decoration: none;
}
.bankAdnotationLink,
.bankAdnotationLink:hover {
  cursor: pointer;
}
.bankAdnotationLinkText,
.bankAdnotationLinkText:hover {
  margin-right: 10px;
}
