@import "src/theme/colors.module";

.specialOfferContainer {
  color: $color-green-100;
  display: flex;
  flex-direction: column;
  align-items: center;

  .headingTextBold {
    font-size: 22px;
    font-weight: 600;
    line-height: 24px;
  }

  .headingText {
    font-size: 22px;
    line-height: 32px;
    font-weight: 400;

    .bold{
      font-weight: 600;
    }
  }

  .lightText {
    padding: 20px 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
  }

  .imageWrapper {
    padding: 20px 0;
    display: flex;
    justify-content: center;
  }

}
