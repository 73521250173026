@import "src/theme/colors.module";
@import "src/theme/breakpoints.module";

.benefitTextStyles {
  font-size: 16px;
  color: $color-green-100;
  font-weight: 600;
};


.textCardStyles {
  font-size: 16px;
  line-height: 1.1;
  font-weight: 600;
  color: $color-white;
  text-align: right;

  @include bp-below(sm) {
    font-size: 14px;
  }
};

.mainPageContainer {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.cursorPointer {
  cursor: pointer;
}
