@import "src/theme/colors.module";


.formFieldsWrapper {
  & > div {
    margin-bottom: 12px;
  }
}

.formCheckbox {
  display: flex;
  align-items: center;
  label {
    padding-top: 3px;
    font-size: 16px;
    margin-left: 10px;
    color: $color-gray-100;
    font-weight: normal;
  }
}

.znpInput {
  div {
    label {
      padding-top: 3px;
      font-size: 16px;
      font-weight: normal;
      color: $color-gray-100;
      font-style: italic;
    }
  }
}
