@import "src/theme/breakpoints.module";

.greenArrow {
  padding-left: 5px;
}

.notificationRowWrapper {
  padding: 10px;
  border-bottom: 1px solid #ececec;
}

.notificationRow {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.textRead {
  font-weight: 400;
  margin-left: 5px;
}

.textUnRead {
  margin-left: 5px;
  font-size: 15px;
  font-weight: 700;
}

.textContent {
  margin-bottom: 0;
  padding: 5px;
}

.containerFlexEnd {
  display: flex;
  justify-content: flex-end;
}

.showMore {
  border: 0;
  padding: 15px;
  font-weight: 600;
  width: 100%;
  background: #fff;
  border-top: 1px solid #ececec;
}

.pickNotification {
  text-align: center;
  font-weight: 700;
}

.paddingLeft {
  padding-left: 10px;
}

.popoverBody {
  padding: 0;
}

.counter {
  position: absolute;
  bottom: -8px;
  right: -8px;
  color: #fff;
  width: 22px;
  height: 22px;
  background: red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notificationPopover {
  @include bp-above(sm) {
    max-width: 500px;
  }
}
