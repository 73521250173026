.pagination {
  justify-content: center;
}

ul {
  list-style: none;
  padding: 0;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

ul.pagination li {
  display: inline-block;
  width: 50px;
  height: 40px;
  border: 1px solid #e2e2e2;
  text-align: center;
  line-height: 33px;
  font-size: 25px;
}

ul.pagination li a {
  text-decoration: none;
  color: black;
  font-size: 20px;
  height: 100%;
  padding: 0 !important;
}

ul.pagination li.active a {
  color: white;
  background-color: #0bc268 !important;
  border-color: #0bc268 !important;
}
ul.pagination li.active {
  background-color: #0bc268;
}

ul.pagination li a:hover {
  color: #0bc268;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #0bc268;
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
