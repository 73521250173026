@import "src/theme/colors.module";

.autoInfo {
  height: auto;
  width: 90%;
  border-radius: 10px;
  background: #f5f5f5;
  margin: 0 auto;
  margin-top: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.labelText {
  font-size: 16px;
  line-height: 20px;
  padding-left: 10px;
  padding-top: 4px;
}

.hrefLink {
  color: green;
  text-decoration: none;
  cursor: pointer;
}

.hrefLinkOrange {
  @extend .hrefLink;
  color: $color-orange-100;
}

.widthButton {
  width: 90%;
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.formInfoText {
  font-weight: 600;
  padding-top: 5px;
  padding-left: 26px;
  font-style: italic;
  line-height: 20px;
}

.checkboxError {
  margin-left: 26px;
  margin-top: 5px;
  color: red;
  font-size: 12px;
}
