@import "src/theme/colors.module";

.regulationsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 16px;
  row-gap: 10px;

  .asteriskExplanation {
    color: $color-green-100;
    font-weight: 600;
    padding-right: 30px;
    margin-bottom: 10px;
  }

  .regulationItem {
    display: flex;
    align-items: center;
    color: $color-green-200;
    line-height: 25px;
    cursor: pointer;

    .arrowButtonWrapper {
      display: flex;
      justify-content: flex-end;
      width: 30px;
      .arrowButton {
        height: 23px;
      }
    }
  }
}

.representativeExample {
  font-size: 12px;
  line-height: 16px;
  color: $color-gray-100;
  margin-top: 30px;
  text-align: justify;
}
